import ProductCard from "@/components/cards/ProductCard/index.vue";
import pagination from "@/components/pagination/index.vue";
import groupProduct from "@/components/group-product/index.vue";
import sortSelect from "./components/sort-select/index.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { directive as onClickaway } from "vue-clickaway";
import _ from "lodash";
export default {
    name: "catalog",
    directives: {
        onClickaway: onClickaway,
    },
    components: {
        groupProduct,
        ProductCard,
        sortSelect,
        pagination,
    },
    data() {
        return {
            loadingProducts: false,
            loadingFilters: false,
            products: [],
            filters: [],
            openFilter: false,
            storedCatalogType: null,
            paginator: {
                hasNextPage: false,
                page: 1,
                perPage: 3 * 4, // load 4 lines per 3 product
            },
            payload: {
                page: 1,
                per_page: 16,
            }
        };
    },
    watch: {
        '$route.params.slug': {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.fetchIngredientData(newVal)
                }
            }
        },
        '$route': {
            immediate: true,
            handler(newVal){
                if(newVal){
                    this.payload.page = this.$route.params.page ? Number(this.$route.params.page) : 1
                    this.fetchIngredientProductsList({slug: this.$route.params.slug, payload: Object.assign(this.payload, this.$route.query)})
                }
            }
        }
    },
    created() {
        this.storedCatalogType = this.catalogType;
    },
    computed: {
        ...mapGetters({
            isAuthenticated: "auth/isAuthenticated",
            favourites: "favorites/whichList",
            ingredientData: 'ingredients/ingredientData',
            ingredientDataLoading: 'ingredients/ingredientDataLoading',
            ingredientProductsList: 'ingredients/ingredientProductsList',
            ingredientProductsListLoading: 'ingredients/ingredientProductsListLoading',
        }),
        productsList() {
            if (!this.isAuthenticated || !this.favourites) {
                return this.ingredientProductsList.data;
            }

            const favourites = _.map(this.favourites, "productId");
            const markAsSelected = product => {
                product.isFavorite = favourites.includes(product.id);
                return product;
            };

            if (this.catalogType === "table") {
                return _.map(this.ingredientProductsList.data, markAsSelected);
            }

            return this.ingredientProductsList.data.map(productGroup => {
                _.map(productGroup.products, markAsSelected);

                return productGroup;
            });
        },
        catalogType() {
            if (this.$route.query.catalog_type === "groups") {
                return "groups";
            }
            return "table";
        },
    },
    methods: {
        ...mapActions({
            fetchIngredientProductsList: 'ingredients/GET_INGREDIENT_PRODUCTS_LIST',
            fetchIngredientData: 'ingredients/GET_INGREDIENT_DATA',
        }),
        ...mapMutations({}),
        openFilters() {
            this.openFilter = !this.openFilter;
            if (this.openFilter) {
                document.getElementById("html").classList.add("hide");
            } else {
                document.getElementById("html").classList.remove("hide");
            }
        },
        away: function() {
            this.openFilter = !this.openFilter;
        },

    },
};
