export default {
    name: "pagination",
    props: {
        defaultName: {
            type: String,
            default: ()=> ''
        },
        paginatedName: {
            type: String,
            default: ()=> ''
        },
        currentPage: {
            type: Number,
            required: true
        },
        totalPages: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
        };
    },
    components: {
    },
    computed: {
        visiblePages() {
            const pages = [];
            const total = this.totalPages;
            const current = this.currentPage;

            if(total < 8){
                for (let i = 1; i <= total; i++) {
                    pages.push(i);
                }
            } else {
                for (let i = 1; i <= Math.min(3, total); i++) {
                    pages.push(i);
                }


                if (current > 4) {
                    pages.push('...');
                }

                const start = Math.max(4, current - 1);
                const end = Math.min(total - 3, current + 1);
                for (let i = start; i <= end; i++) {
                    pages.push(i);
                }

                if (end < total - 3) {
                    pages.push('... ');
                }

                for (let i = Math.max(total - 2, end + 1); i <= total; i++) {
                    pages.push(i);
                }
            }


            return pages;
        }
    },
    mounted() {
    },
    methods: {

    },
};
